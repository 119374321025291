<template>
  <div>
    <b-modal
      v-model="showModal"
      ref="modal"
      centered
      size="md"
      class="alert-modal"
      hide-header-close
      footer-class="pt-0 border-0"
    >
      <template #modal-header> <div class="modal-title">
        เปลี่ยนเวลา
      </div>  </template>
      <div class="text-center">
        <div class="d-flex justify-content-center my-3 text-black text-center w-100">
          <div class="text-center w-50">
            <InputTextMask
              :placeholder="placeholder"
              :value="form.changeTime"
              className="mb-2"
             mask="##/##/#### ##:##"
              masked
              @onDataChange="changeTime"
            
            />
            <p>กรุณาระบุเป็น ปี พ.ศ.
              <P class="f-12 text-gray">dd/mm/yyyy hh:mm</P>
            </p>
          </div>
          
        </div>
        <div v-if="$v.form.changeTime && $v.form.changeTime.$error">
          <span class="text-error" v-if="$v.form.changeTime.required == false">
            กรุณากรอกเวลาที่ปรับต้อง
          </span>
          <span class="text-error" v-else-if="$v.form.changeTime.minValue == false">
            เวลาที่ปรับต้องไม่น้อยกว่าวันที่<br /> {{ $moment(allowDay).format( "DD MMMM YYYY HH:mm" ) }} 
          </span>
          <span class="text-error" v-else-if="$v.form.changeTime.maxValue == false">
            เวลาที่ปรับต้องไม่มากกว่าเวลาปัจจุบัน
          </span>
        </div>
        <div class="f-14 text-danger mt-2" v-if="form.changeTime && !$moment(form.changeTime, 'DD/MM/YYYY HH:mm').isValid() && isValidate">
          <p> วันที่และเวลาไม่ถูกต้อง</p>
        </div>
      </div>
      <template #modal-footer="{ hide }">
        <div class="d-flex justify-content-between w-100 my-0">
          <b-button
            variant="primary-color"
            class="btn-modal btn-hover w-50 mr-1"
            @click="save"
            :disabled="isLoading"
          >
            ยืนยัน
          </b-button>
          <b-button
            variant="close"
            class="btn-modal btn-hover w-50 ml-1"
            @click="hide('forget')"
            :disabled="isLoading"
          >
            ยกเลิก
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import InputTextMask from "@/components/inputs/InputTextMask";
import { required, minValue, maxValue } from "vuelidate/lib/validators";
import { TheMask } from "vue-the-mask";
import { mapGetters } from "vuex";

export default {
  components: {
    InputTextMask,
    TheMask,
  },
  props: {
    branchId: {
      required: true,
      type: [String, Number],
    },
  },
  data() {
    return {
      isLoading: false,
      showModal: false,
      form: { changeTime: "" },
      today: null,
      allowDay: null,
      allowTimeChange: {
        result: 0,
        allowDay: 0,
        allowMonth:0
      },
      isFirstEdit: true,
      placeholder: '',
      isValidate: false,
      mask: '',
    };
  },
  validations() {
    return {
      form: {
        changeTime: {
          required,
          minValue(value) {
            if(this.$moment(value, "DD/MM/YYYY HH:mm").isValid())
            {
              let dateTime = this.$moment(value, "DD/MM/YYYY HH:mm").unix();
              let allowDay = this.$moment(this.allowDay).unix();
              return dateTime >= allowDay;
            }
            return true;
          },
          maxValue(value) {
            if(this.$moment(value, "DD/MM/YYYY HH:mm").isValid())
            {
              let dateTime = this.$moment(value, "DD/MM/YYYY HH:mm").unix();
              let now = this.$moment().add(543, "years").unix();
              return dateTime <= now;
            }
            return true;
          },
        },
      },
    };
  },
  async created() {
    await this.getAllowTimeChange();
    this.today = this.$moment().add(543, "years").format("DD/MM/YYYY HH:mm");
    this.form.changeTime = this.today;
    this.allowDay = this.$moment().add(this.allowTimeChange.allowDay*-1, "days");
    this.allowDay = this.$moment(this.allowDay ).add(543, "years").format("YYYY-MM-DD HH:mm:ss");
    
  },
  computed: {
    ...mapGetters({
      dateTimeFix: "getDateTimeFix",
    }),
  },
  methods: {
    async getAllowTimeChange() {
      let branchId = this.$cookies.get("branch_id");
      if(branchId){
        await this.axios
          .get(
            `${this.$baseUrl}/transaction/get_allow_time_change/${branchId}`
          )
          .then(async (data) => {
            if (data.result == 1) {
              this.allowTimeChange.result = data.detail.result;
              this.allowTimeChange.allowDay = data.detail.allow_day;
              this.allowTimeChange.allowMonth = data.detail.allow_month;
            }
          });
      }
    },
    async show() {
      this.$v.form.$reset();
      this.isValidate = false
    
      let dateTime = this.today;
      if(this.dateTimeFix){
        dateTime = this.$moment(this.dateTimeFix).add(543, "years").format("DD/MM/YYYY HH:mm");
      }else{
        dateTime = this.$moment().add(543, "years").format("DD/MM/YYYY HH:mm");
      }
      this.form.changeTime = "DD/MM/YYYY HH:MM";
      this.placeholder = "DD/MM/YYYY HH:MM";
      this.showModal = true;
    },
    hide() {
      this.showModal = false;
    },
    changeTime(val) {

        this.form.changeTime = val;
      
    },
    
    async save() {
      // if(this.form.changeTime === "DD/MM/YYYY HH:MM"){
      //   this.form.changeTime = this.today
      // }
 
      if (this.allowTimeChange.result == 0){
        this.warningAlert("ไม่สามารถย้อนเวลาได้");
      } else {
        this.$v.form.$touch();
        this.isValidate = true
        if (this.$v.form.$error || !this.$moment(this.form.changeTime, "DD/MM/YYYY HH:mm").isValid()) return;
      
        this.isLoading = true;
        let dateTime = await this.$moment(this.form.changeTime, "DD/MM/YYYY HH:mm").add(-543, "years").format(
          "YYYY-MM-DDTHH:mm:ss"
        );
        try {
          this.isLoading = false;
          await this.$store.dispatch("setDateTimeFix", dateTime);
          await this.successAlert();
          await this.hide();
        } catch (error) {
          this.warningAlert(error);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal-header {
  color: #fff;
  width: 100%;
  background-color: var(--primary-color);


}
::v-deep .modal-body {
  padding: 1rem 1rem;
}
.btn-primary .modal-body {
  margin: auto;
  text-align: center;
}
::v-deep .nav-tabs li:nth-child(2) {
  border-left: 1px solid white;
  border-right: 1px solid white;
}

@media (max-width: 768px) {
  ::v-deep .div-input {
    width: 100%;
  }
  ::v-deep .modal-dialog {
    margin: 0px;
    max-width: 100vw !important;
    .modal-content {
      width: 100vw;
      height: 100vh;
      .modal-body{
        overflow-y: auto;
      }
    }
  }
}
</style>
