<template>
  <div
    class="loading-mask d-flex flex-column align-items-center justify-content-center"
    :style="`background-color: ${$store.state.colorPrimary}`"
  >
    <p class="d-flex flex-row">
      User Data:
      {{ getDataLoadingProgressByData().userData ? "&#10004;" : "&#10008;" }}
    </p>

    <div class="progress">
      <div
        class="progress-bar"
        role="progressbar"
        :style="{
          width: `${getDataLoadingProgress()}%`,
          'background-color': $store.state.colorSecondary,
        }"
        aria-valuenow="50"
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      progress: 0,
    };
  },
  computed: {
    ...mapGetters([
      "getDataLoadingProgress",
      "getDataLoadingProgressByData",
      "getBrandDetail",
    ]),
  },
  created() {
    this.interval = setInterval(() => {
      this.updateDataProgress();
      this.progress = this.getDataLoadingProgress();
      if (this.progress == 100) {
        clearInterval(this.interval);
      }
    }, 100);
  },
  methods: {
    ...mapActions(["updateDataProgress"]),
  },
};
</script>

<style>
.loading-mask {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 2147483647;
  /* background: linear-gradient(90deg, #8f20c6, #5cb7d9); */
}
</style>
