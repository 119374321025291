<template>
  <div>
    <b-modal
      ref="logOutModal"
      id="logOutModal"
      centered
      footer-class="pt-0 border-0"
    >
      <template #modal-header="{ close }">
        <div class="modal-title">ออกจากระบบ</div>
      </template>

      <div class="text-center px-md-5 py-4">
        <p>คุณแน่ใจหรือไม่ว่าคุณต้องการออกจากระบบ?</p>
      </div>
      <template #modal-footer="{ hide }">
        <div class="d-flex justify-content-between w-100 my-0">
          <b-button
            variant="close"
            class="btn-modal btn-hover w-50 mr-1"
            @click="$bvModal.hide('logOutModal')"
          >
            ยกเลิก
          </b-button>
          <b-button
            variant="primary-color"
            class="btn-modal btn-hover w-50 ml-1"
            @click="logout"
          >
            ยืนยัน
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-modal
      ref="alertModal"
      id="alertModal"
      hide-header
      hide-footer
      centered
      size="md"
      no-close-on-backdrop
    >
      <div class="text-center m-5">
        <p class="">กรุณารอสักครู่...</p>
        <b-spinner
          label="Loading..."
          variant="dark"
          class="mt-2 spinner-main"
        ></b-spinner>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    handleLogout() {
      this.$refs.logOutModal.show();
    },
    logout() {
      this.$refs.logOutModal.hide();
      this.$refs.alertModal.show();
      var timestamp = new Date().getTime();
      this.$cookies.set('timestamp', timestamp);
      this.$cookies.remove('tnl-username');
      this.$cookies.remove('token');
      this.$cookies.remove('username');
      this.$cookies.remove('branch_id');
      this.$cookies.remove('plant_id');
      this.$cookies.remove('user_guid');
      this.$cookies.remove('sales_no');
      this.$cookies.remove('booth');
      this.$cookies.remove('spa');
      this.$cookies.remove('invoice_length');
      this.$cookies.remove('is_fullpos');
      this.$cookies.remove('is_invoice_leading_zero');
      this.$cookies.remove('allow_past_coupon');
      this.$cookies.remove('account_name');
      this.$cookies.remove('allow_time_change');
      this.$cookies.remove('booth_show_inventory');
      this.$cookies.remove('branch_name');
      this.$cookies.remove('point_system');
      this.$cookies.remove('allow_time_change_service');

      window.location.href = "/";
    },
  },
};
</script>

<style scoped>
::v-deep .modal-header {
  color: #fff;
  width: 100%;
  background-color: var(--primary-color);
}


@media (max-width: 768px) {
  ::v-deep .modal-title {
    text-align: center;
    width: 100%;
  }
}
</style>
