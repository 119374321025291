<script>
export default {
  name: "nav",
  data() {
    return {
      permissionList: [],
      sidebar: {
        _name: "CSidebarNav",
        _children: [
          {
            _name: "CSidebarNavItem",
            name: "หน้าขาย",
            to: "/home",
            // icon: "cil-home",
            _attrs: { style: `background-color: white; color: ${this.$store.state.colorPrimary} !important;`},
          },
          {
            _name: "CSidebarNavItem",
            name: "ออกจากระบบ",
            to: "/login",
            // icon: "cil-list",
            // _attrs: { style: this.$store.state.backgroundPrimary },
            _attrs: { style: `background-color: white; color: ${this.$store.state.colorPrimary} !important;`},
          },
          // {
          //   _name: "CSidebarNavItem",
          //   name: "แบทช์คลังสินค้า",
          //   to: "/batch",
          //   icon: "cil-list",
          // },
          // {
          //   _name: "CSidebarNavItem",
          //   name: "ออเดอร์",
          //   to: "/order",
          //   icon: "cil-list",
          // },
        ],
      },
    };
  },
  computed: {
    sidebarItems() {
      return [this.sidebar];
    },
  },
  created: async function() {},
  methods: {},
};
</script>
