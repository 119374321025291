<template>
  <CSidebar
    :minimize="minimize"
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand
      class="d-md-down-none"
      to="/"
      :style="$store.state.backgroundPrimary"
    >
      <div class="m-auto logo-temp text-uppercase text-center">
        <div
          class="imgLogo"
          v-bind:style="{
            'background-image': 'url(' + $store.state.logoHomePage + ')',
          }"
        ></div>
      </div>
    </CSidebarBrand>
    <div class="text-center mt-2 pt-2 d-none" :class="[{ 'd-none': minimize }]">
      <img :src="img" alt="logo" class="w-25" />
      <h4 class="font-weight-bold mt-2 username">{{ name }}</h4>

      <b-row class="no-gutters py-3 user-btn mt-4">
        <b-col>
          <div>
            <font-awesome-icon icon="user" />
          </div>
          <p class="m-0">Profile</p>
        </b-col>
        <b-col class="pointer" @click.prevent="handleLogout">
          <div>
            <font-awesome-icon icon="power-off" />
          </div>
          <p class="m-0">Logout</p>
        </b-col>
      </b-row>
    </div>

    <CRenderFunction flat :contentToRender="sidebarItems" />
    <CSidebarMinimizer
      class="c-d-md-down-none"
      @click.native="$store.commit('toggle', 'sidebarMinimize')"
    />

    <ModalAlertConfirm
      msg="Log out"
      :text="modalMessage"
      colorBtnConfirm="primary"
      btnCancel="Cancel"
      btnConfirm="OK"
      ref="isModalAlertConfirm"
      @confirm="logout"
    />
  </CSidebar>
</template>

<script>
import SidebarItems from "./SidebarItems";
import { mapState } from "vuex";
import ModalAlertConfirm from "@/components/modal/alert/ModalAlertConfirm";
import VueCookies from "vue-cookies";
import img from "@/assets/images/icons/logo.svg";

export default {
  name: "TheSidebar",
  extends: SidebarItems,
  components: {
    ModalAlertConfirm,
  },
  data() {
    return {
      modalMessage: "Are you sure you want to log out ?",
      name: "",
      img: img,
      imgLogo: "",
      navFiltered: null,
    };
  },
  computed: {
    show() {
      return this.$store.state.sidebarShow;
    },
    ...mapState({
      minimize: (state) => state.sidebarMinimize,
    }),
  },
  created() {},
  mounted: async function () {
    this.name = VueCookies.get("username");
    this.$root.$on("toggle-sidebar", () => {
      this.$store.commit('toggleSidebarCustom')
    });
  },
  methods: {
    handleLogout() {
      this.$refs.isModalAlertConfirm.show();
    },
    logout: async function () {
      this.$refs.isModalAlertConfirm.hide();
      this.$cookies.remove("tnl_session_token");
      window.location.href = "/login";
    },
  },
};
</script>

<style scoped>
#logo-main {
  background-image: url("/img/brand/coreui-base-white.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.logo-temp {
  color: #ffb300;
  font-size: 24px;
  font-weight: bold;
  width: 90% !important;
}

.user-btn {
  background: #373122;
}

.username {
  font-size: 18px;
}
</style>
