var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-sidebar',{attrs:{"id":"sidebar-menu","backdrop":"","shadow":"","backdrop-variant":"dark","aria-labelledby":"sidebar-menu-title","no-header":""},on:{"hidden":function($event){return _vm.hide()}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('div',{staticClass:"menu-item p-2 border-top",on:{"click":function($event){return _vm.$refs.modalLogout.handleLogout()}}},[_c('label',{staticClass:"marginbottom"},[_vm._v("ออกจากระบบ")])])]},proxy:true}]),model:{value:(_vm.$store.state.slidebarCustom),callback:function ($$v) {_vm.$set(_vm.$store.state, "slidebarCustom", $$v)},expression:"$store.state.slidebarCustom"}},[[_c('div',[_c('div',{staticClass:"p-2 border-bottom d-flex justify-content-between align-items-center"},[_c('label',{staticClass:"m-0 header-text"},[_c('strong',[_vm._v("เมนู")])]),_c('label',{staticClass:"m-0 pointer",on:{"click":function($event){return _vm.$store.commit('toggleSidebarCustom')}}},[_c('b-icon',{attrs:{"icon":"x-circle-fill"}})],1)]),_vm._l((_vm.menuOption),function(menu,index){return _c('div',{key:index,on:{"click":function($event){$event.stopPropagation();return _vm.gotoPath(menu)}}},[(_vm.isDisplayMenu(menu.id))?_c('div',{staticClass:"menu-item p-2",style:(_vm.$route.fullPath == menu.path ||
              _vm.$route.meta.activeUrl == menu.path
                ? `border-left: 4px solid ${_vm.$store.state.colorPrimary};`
                : ``)},[_vm._v(" "+_vm._s(menu.name)+" ")]):_vm._e(),_vm._l((menu.children),function(menuChild,index){return _c('div',{key:index,on:{"click":function($event){$event.stopPropagation();return _vm.gotoPath(menuChild)}}},[_c('div',{staticClass:"menu-item p-2 pl-4",style:(_vm.$route.fullPath == menuChild.path ||
                _vm.$route.meta.activeUrl == menu.path
                  ? `border-left: 4px solid ${_vm.$store.state.colorPrimary};`
                  : ``)},[_vm._v(" "+_vm._s(menuChild.name)+" ")])])})],2)})],2)]],2),_c('b-modal',{ref:"alertModal",attrs:{"id":"alertModal","hide-header":"","hide-footer":"","centered":"","size":"sm","no-close-on-backdrop":""}},[_c('div',{staticClass:"text-center"},[_c('img',{staticClass:"mb-3 img",attrs:{"src":require("@/assets/images/icons/loading.svg"),"alt":"loading"}}),_c('p',{staticClass:"font-weight-bold"},[_vm._v("กรุณารอสักครู่...")])])]),_c('ModalLogout',{ref:"modalLogout"}),_c('ModalRegister',{ref:"modalRegister",attrs:{"branchId":_vm.branchId}}),_c('ModalChangeTime',{ref:"modalChangeTime",attrs:{"branchId":_vm.branchId}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }